import React, { useState, useContext, useEffect, useMemo } from "react";
import millify from "millify";

import iconBull from "../../assets/images/icons/icon-bull-light.svg";
import iconBear from "../../assets/images/icons/icon-bear-light.svg";
import iconNeutralLight from "../../assets/images/icons/icon-neutral-gray.svg";

import { SocketioContext } from "../../context/SocketioContext";
import { ThemeDetailsContext } from "../../context/ThemeContext";
// dark mode images
import { getUserSession } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../components/BootstrapIcon";
import { useAuth, useIsPremiumUser } from "src/context/AuthContext";
import { useGetHeaderDetails } from "src/services/tickerService";
import iconArrowDown from "src/assets/images/icons/icon-arrow-down-right-light.svg";
import iconArrowUp from "src/assets/images/icons/icon-arrow-up-right-light.svg";
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const MarketSentiments = () => {
  const {
    // marketSentiment,
    liveFeed,
    setLiveFeed,
  } = useContext(SocketioContext);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const { data: marketSentiment, refetch, isFetched } = useGetHeaderDetails();
  const plan = getUserSession("planDetail");
  const { theme, setExpandedMenu, expandedMenu } =
    useContext(ThemeDetailsContext);
  const [trends, setTrend] = useState({
    marketUp: true,
    putUp: true,
    putRatioUp: true,
    putPremiumUp: true,
    putCallRatio: 0,
  });
  useEffect(() => {
    refetch()
  }, [])


  useEffect(() => {
    let currentTrend = {
      marketUp: true,
      putUp: true,
      putRatioUp: true,
      putPremiumUp: true,
      putCallRatio: 0,
    };
    let putCallRatio = (
      (marketSentiment?.netPut / marketSentiment?.netCall) *
      100
    ).toFixed(2);
    if (!isNaN(putCallRatio)) {
      currentTrend.putCallRatio = putCallRatio;
    } else {
      currentTrend.putCallRatio = 0;
    }
    const sentiments = [
      marketSentiment?.bullishCount,
      marketSentiment?.bearishCount,
      //marketSentiment?.neutralCount,
    ];
    currentTrend.marketUp = sentiments.indexOf(Math.max(...sentiments));
    if (marketSentiment?.netPut < marketSentiment?.netCall) {
      currentTrend.putUp = true;
      currentTrend.putRatioUp = true;
    } else {
      currentTrend.putUp = false;
      currentTrend.putRatioUp = false;
    }
    if (
      Math.round(marketSentiment?.netPutPremium) <
      Math.round(marketSentiment?.netCallPremium)
    ) {
      currentTrend.putPremiumUp = true;
    } else {
      currentTrend.putPremiumUp = false;
    }
    setTrend(currentTrend);
  }, [marketSentiment]);
  const getFortatedValue = (value) => {
    return millify(value, {
      units: ["", "K", "M", "B", "T", "P", "E"],
      space: true,
    });
  };
  const handelCahnge = (value) => {
    if (value == "logout") {
      logout();
    }
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      <div
        className={`top-market-trends align-items-center d-flex justify-content-between flex-grow-1 py-2 border border-1 rounded-3 ${theme == "dark" ? "border-transparent" : " border-gray-300"
          }`}
      >
        <div className="px-lg-3 px-xl-5 px-2">
          <p className="mb-1 fs-8 fs-xl-7 fw-medium text-gray-600">Market Sentiment</p>
          <div className="d-inline-flex align-items-center">
            {
              (isFetched) ? (
                <>
                  <span
                    className={`badge rounded-pill me-1 py-1 px-2 ${trends.marketUp === 0
                      ? "bg-success"
                      : trends.marketUp === 1
                        ? "bg-danger"
                        : "bg-gray-300"
                      }`}
                  >
                    <img
                      src={
                        trends.marketUp === 0
                          ? iconBull
                          : trends.marketUp === 1
                            ? iconBear
                            : iconNeutralLight
                      }
                      height="10px"
                      width="9px"
                      alt=""
                    ></img>
                  </span>
                  <p className="mb-0 fs-8 fs-xxl-7 fw-medium">
                    {trends.marketUp === 0
                      ? "Bullish"
                      : trends.marketUp === 1
                        ? "Bearish"
                        : "Neutral"}
                  </p>
                </>
              ) : (
                <span className="loading-dots">..</span> // Show loading animation when fetching data
              )
            }
            
          </div>
        </div>
        <div className={`px-lg-3 px-xl-5 px-2 border-start border-1 ${theme == "dark" ? "border-gray-200" : " border-gray-300"
          }`}>
          <p className="mb-1 fs-8 fs-xl-7 fw-medium text-gray-600">Put / Call Ratio</p>
          <div className="d-inline-flex align-items-center">

            {
              (isFetched) ? (
                <>

                  <span
                    className={`badge rounded-pill me-1 py-1 px-2 ${trends.putUp ? " bg-success" : " bg-danger"
                      }`}
                  >
                    {trends.putUp ? (
                      <img
                        width={14}
                        height={12}
                        alt="up or down"
                        style={{
                          objectFit: "fill",
                          background: "rgba(6, 137, 61, 1)",
                          // padding: 2,
                          borderRadius: 16,
                        }}
                        src={iconArrowUp}
                      ></img>
                    ) : (
                      <img
                        width={14}
                        height={12}
                        alt="up or down"
                        style={{
                          objectFit: "fill",
                          background: "rgba(219, 0, 0, 1)",
                          // padding: 2,
                          borderRadius: 16,
                        }}
                        src={iconArrowDown}
                      ></img>
                    )}
                  </span>
                  <p className="mb-0 fs-8 fs-xxl-7 fw-medium">{trends.putCallRatio}%</p>
                </>
              ) : (
                <span className="loading-dots">..</span> // Show loading animation when fetching data
              )
            }

          </div>
        </div>
        <div className={`px-lg-3 px-xl-5 px-2 border-start border-1 ${theme == "dark" ? "border-gray-200" : " border-gray-300"
          }`}>
          <p className="mb-1 fs-8 fs-xl-7 fw-medium text-gray-600">Put / Call Volume</p>
          <div className="d-inline-flex align-items-center">
            {
              (isFetched) ? (
                <>
                  <span
                    className={`badge rounded-pill me-1 py-1 px-2 ${trends.putRatioUp ? "bg-success" : "bg-danger"}`}
                  >
                    {trends.putRatioUp ? (
                      <img
                        width={14}
                        height={12}
                        alt="up arrow"
                        style={{
                          objectFit: "fill",
                          background: "rgba(6, 137, 61, 1)",
                          borderRadius: 16,
                        }}
                        src={iconArrowUp}
                      />
                    ) : (
                      <img
                        width={14}
                        height={12}
                        alt="down arrow"
                        style={{
                          objectFit: "fill",
                          background: "rgba(219, 0, 0, 1)",
                          borderRadius: 16,
                        }}
                        src={iconArrowDown}
                      />
                    )}
                  </span>
                  <p className="mb-0 fs-8 fs-xxl-7 fw-medium">
                    {marketSentiment?.netPut === undefined || marketSentiment?.netPut === null ? (
                      <span className="loading-dots">Loading...</span> // Show loading animation when netPut is not available
                    ) : (
                      <>
                        {getFortatedValue(marketSentiment?.netPut)} / {getFortatedValue(marketSentiment?.netCall)}
                      </>
                    )}
                  </p>
                </>
              ) : (
                <span className="loading-dots">..</span> // Show loading animation when fetching data
              )
            }


          </div>

        </div>
        <div className={`px-lg-3 px-xl-5 px-2 border-start border-1 ${theme == "dark" ? "border-gray-200" : " border-gray-300"
          }`}>
          <p className="mb-1 fs-8 fs-xl-7 fw-medium text-gray-600">
            Put / Call Premium
          </p>
          <div className="d-inline-flex align-items-center">

            {
              (isFetched) ? (
                <>
                  <span
                    className={`badge rounded-pill py-1 me-1 px-2 ${trends.putPremiumUp ? " bg-success" : " bg-danger"
                      }`}
                  >
                    {trends.putPremiumUp ? (
                      <img
                        width={14}
                        height={12}
                        alt="up or down"
                        style={{
                          objectFit: "fill",
                          background: "rgba(6, 137, 61, 1)",
                          borderRadius: 16,
                        }}
                        src={iconArrowUp}
                      ></img>
                    ) : (
                      <img
                        width={14}
                        height={12}
                        alt="up or down"
                        style={{
                          objectFit: "fill",
                          background: "rgba(219, 0, 0, 1)",
                          borderRadius: 16,
                        }}
                        src={iconArrowDown}
                      ></img>
                    )}
                  </span>
                  <p className="mb-0 fs-8 fs-xxl-7 fw-medium">
                    ${getFortatedValue(marketSentiment?.netPutPremium)} / $
                    {getFortatedValue(marketSentiment?.netCallPremium)}
                  </p>
                </>
              ) : (
                <span className="loading-dots">..</span> // Show loading animation when fetching data
              )
            }
            
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketSentiments;
