import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./components/ErrorBoundary/Fallback";
import { StableNavigateContextProvider } from "./context/StableNavigateContext";
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <BrowserRouter>
  <StableNavigateContextProvider>

    <AuthProvider>
      <ErrorBoundary
        FallbackComponent={Fallback}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <App />
      </ErrorBoundary>
    </AuthProvider>
  </StableNavigateContextProvider>

  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
