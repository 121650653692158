import { createContext, useState } from "react";
import { filterEmptyObjects } from "../utils";

export const FilterContext = createContext();
let initialFilterOptions = {
  tickers: [],
  side: "",
  strikePrice: {
    from: "",
    to: "",
  },
  contractType: "",
  contractExpiration: {
    from: "",
    to: "",
  },
  bid: {
    from: "",
    to: "",
  },
  ask: {
    from: "",
    to: "",
  },
  price: {
    from: "",
    to: "",
  },
  size: {
    from: "",
    to: "",
  },
  premium: {
    from: "",
    to: "",
  },
  openInterest: {
    from: "",
    to: "",
  },
  volume: {
    from: "",
    to: "",
  },
  contractSize: {
    from: "",
    to: "",
  },
  type: "",
  sentiment: "",
};
const createFilterState = () => {
  return {
    filters: filterEmptyObjects({ ...initialFilterOptions }),
    appliedFilters: filterEmptyObjects({ ...initialFilterOptions }),
  };
};
const FilterProvider = ({ children }) => {
  const [tickerFilters, setTickerFilters] = useState(createFilterState());
  const [darkPoolFilters, setDarkPoolFilters] = useState(createFilterState());
  const [hotChainFilters, setHotChainFilters] = useState(createFilterState());
  const [tickerHotChainFilters, setTickerHotChainFilters] = useState(createFilterState());
  const [flowPicksFilters, setFlowPicksFilters] = useState(createFilterState());

// todo: use ticker filter instead of these two
  const [filters, setFilters] = useState(
    filterEmptyObjects(initialFilterOptions)
  );
  const [appliedFilters, setAppliedFilters] = useState(
    filterEmptyObjects(initialFilterOptions)
  );

  // common
  const handleFilters = (setFilters, filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      filters: filterEmptyObjects(filters),
    }));
  };
  const handleAppliedFilters = (setFilters, appliedFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      appliedFilters: filterEmptyObjects(appliedFilters),
    }));
  };

  const handleTickerFilters = (filters) => {
    handleFilters(setTickerFilters, filters);
  };
  const handleAppliedTickerFilters = (appliedFilters) => {
    handleAppliedFilters(setTickerFilters, appliedFilters);
  };

  // dark-pool
  const handleDarkPoolFilters = (filters) => {
    handleFilters(setDarkPoolFilters, filters);
  };
  const handleAppliedDarkPoolFilters = (appliedFilters) => {
    handleAppliedFilters(setDarkPoolFilters, appliedFilters);
  };

  // hot-chain
  const handleHotChainFilters = (filters) => {
    handleFilters(setHotChainFilters, filters);
  };
  const handleAppliedHotChainFilters = (appliedFilters) => {
    handleAppliedFilters(setHotChainFilters, appliedFilters);
  };

  // ticker hot-chain
  const handleTickerHotChainFilters = (filters) => {
    handleFilters(setTickerHotChainFilters, filters);
  };
  const handleAppliedTickerHotChainFilters = (appliedFilters) => {
    handleAppliedFilters(setTickerHotChainFilters, appliedFilters);
  };

  // Unusual Flow Picks
  const handleFlowPicksFilters = (filters) => {
    handleFilters(setFlowPicksFilters, filters);
  };
  const handleAppliedFlowPicksFilters = (appliedFilters) => {
    handleAppliedFilters(setFlowPicksFilters, appliedFilters);
  };

  

  const handelFilters = (filter) => {
    const res = filterEmptyObjects(filter);
    setFilters(res);
  };
  const handelAppliedFilters = (filters) => {
    setAppliedFilters(filters);
  };

  return (
    <FilterContext.Provider
      value={{
        handelFilters,
        filters,
        appliedFilters,
        handelAppliedFilters,

        tickerFilters,
        handleTickerFilters,
        handleAppliedTickerFilters,

        darkPoolFilters,
        handleDarkPoolFilters,
        handleAppliedDarkPoolFilters,

        hotChainFilters,
        handleHotChainFilters,
        handleAppliedHotChainFilters,

        tickerHotChainFilters,
        handleTickerHotChainFilters,
        handleAppliedTickerHotChainFilters,

        flowPicksFilters,
        handleFlowPicksFilters,
        handleAppliedFlowPicksFilters,

      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
