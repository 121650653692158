import { createContext, useState } from 'react';
import { Helmet } from "react-helmet";

export const ThemeDetailsContext = createContext();

const ThemeProvider = (props) => {
  const [theme, setTheme] = useState('dark');
  const [expandedMenu, setExpandedMenu] = useState(false);
  return (
    <ThemeDetailsContext.Provider value={{ theme, setTheme, expandedMenu, setExpandedMenu }}>
      <Helmet>
        <html lang="en" data-bs-theme={theme}/>
        <body className={`${expandedMenu ? "menu-expanded" : ""}`}/>
      </Helmet>
      {props.children}
    </ThemeDetailsContext.Provider>
  );
};

export default ThemeProvider;