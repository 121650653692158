import { useQuery } from "@tanstack/react-query";
import axios from "../utils/axios";

export const queryKey = {
  getPlans: "plans",
  getSession: "getSession",
  getUserPlan: "userPlan",
};

export async function getPlans() {
  let res = await axios.post("/subscription-plans");
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data.products);
  } else {
    return Promise.reject(data);
  }
}
export async function getSession() {
  let res = await axios.post('/strip-session');
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data?.sessionData);
  } else {
    return Promise.reject(data);
  }
}
export async function cancelSubscriptionApi() {
  let res = await axios.get('/stripe-cancel-subscription');
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}
export async function createSubscription({userId, priceId}){
  let res = await axios.post("/create-subscription", { userId , priceId});
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }}

  

// export const useUserPlanQuery = (params) => {
//   return useQuery({
//     queryKey: [queryKey.getUserPlan],
//     queryFn: () => getUserPlanDetails(params?.userId),
//     enabled: !!params?.userId
//   });
// };
export const useGetCustomerSession = (params) =>
  useQuery({ queryKey: [queryKey.getSession], queryFn:() =>  getSession(params), refetchOnMount: true, refetchOnWindowFocus:true });

export const useGetPlansQuery = () =>
  useQuery({ queryKey: [queryKey.getPlans], queryFn: getPlans });
