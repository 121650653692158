import React from 'react'

function TechnologyPartner() {
  return (
    <span className='d-flex align-items-center px-3'>
      <span className='text-gray-500 me-2'>Technology Partner :</span>
      <a href='https://encriss.com/' target='_blank' rel='noreferrer' className='text-hover-dark'>
        Encriss Technologies
      </a>
    </span>
  )
}

export default TechnologyPartner
