import { toArray, flatten, head, mapValues, concat,isObject, pickBy, isNil, isArray, isEmpty, isString } from 'lodash'
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import momentTz from 'moment-timezone'
import moment from 'moment'
// const baseApiUrl = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_SOCKET_API_URL}/api/unusualflow` : `${window.location.protocol}//${window.location.host}/api/unusualflow`
const baseApiUrl = process.env.REACT_APP_AWS_URL

const errorsGetFirst = errors => head(flatten(toArray(errors)))

const apiUrl = (url) => `${baseApiUrl}${url}`

const getUrlParameter = (location, name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]') //no-useless-escape
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(location)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}
const base64ToBlob = (b64Data, contentType='image/png', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
    
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}



const setUserSession = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    //
  }
}

const getUserSession = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || {}
  } catch (err) {
    //
  }
}

const destroyUserSession = (key) => {
  try {
    localStorage.removeItem(key)
  } catch (err) {
    //
  }
}

const setCookie = (key, value) => {
  let cookie = key + "=" + value + ";";
  if (value !== "") {
    let expires = new Date(new Date().getTime() + 30 * 1000 * 60 * 60 * 24);
    cookie += "expires=" + expires.toGMTString() + ";";
  }
  cookie += 'path=/;';
  document.cookie = cookie;
}

const getCookie = key => {
  var name = key + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const getRedirectPath = loggedInUser => {
  var redirectPath = loggedInUser.userType
  if (loggedInUser.userType === 'coachee' && (loggedInUser.totalPendigTests === 0 && loggedInUser.totalCompletedTests > 0)) {
    redirectPath = `${loggedInUser.userType}/report`
  }
  return redirectPath
}

const flattenErrors = errors => {
  if (typeof errors === 'object') {
    return mapValues(errors, error => head(concat(error)))
  }
}
const QueryStringToJSON = (query) => {
  if (!query) {
    return {};
  }
  const queryStrings = query.split('&');
  const queryJSON = {};
  queryStrings.forEach((queryString) => {
    queryString = queryString.split('=');
    queryJSON[queryString[0]] = decodeURIComponent(queryString[1] || '');
  });
  return JSON.parse(JSON.stringify(queryJSON));
}

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const errorMessage = (error) => {
  if (error.response && error.response.status === 404) {
    return error.response.data
      ? error.response.data.message
      : "Network error occurred. Please try after some time!.";
  }
  else if (error.response && error.response.status === 401) {
    return error.response.data
      ? error.response.data.message
      : "Please login to use this service!.";
  }
  else if (error.response && error.response.status === 409) {
    return error.response.data
      ? error.response.data.message
      : "Error during saving information!.";
  } else if (error.response && error.response.status === 400) {
    return error.response.data
      ? error.response.data.message
      : "Error during saving information!.";
  }
  else {
    return "Network error occurred. Please try after some time!.";
  }

}

const SuccessMessage = (response) => {
  if (response && response.status === 201) {
    return response.data
      ? response.data.message
      : "Information save successfully!.";
  } else {
    return response.message
      ? response.message
      : "Success"
  }

}
const jsonToQueryParms = (jsonObject) => {
  const queryParams = new URLSearchParams(jsonObject)
  return queryParams.toString()
}
const currenTimeZone = () => {
  let date = new Date()
  let timeDiff = date.getTimezoneOffset()
  let utcTime = timeDiff / 60
  let gmtTime = utcTime.toString().replace("-", "").split(".")
  utcTime = `${utcTime}${gmtTime[1] === 5 ? ":0-30" : ""}`
  gmtTime = `${gmtTime[0]}:${gmtTime[1] === 5 ? "30" : "00"}`
  return { inGMTHour: gmtTime, inHour: utcTime, inMinutes: timeDiff }
}


const getCurrentTimeAndOffset = () => {
  const date = new Date();

  // Get the current time and format it
  const currentTime = momentTz(date).tz(momentTz.tz.guess()).format('YYYY-MM-DD HH:mm:ss z');

  // Get the time zone abbreviation
  const timeZoneAbbr = momentTz.tz(date, momentTz.tz.guess()).zoneAbbr();

  // Get the difference between the current time and UTC
  const utcOffset = momentTz(date).utcOffset();
  const utcOffsetSeconds = utcOffset * 60
  const utcOffsetHours = Math.floor(utcOffset / 60);
  const utcOffsetMinutes = Math.abs(utcOffset % 60);
  const formattedOffset = `${utcOffset >= 0 ? '+' : '-'}${utcOffsetHours}:${utcOffsetMinutes.toString().padStart(2, '0')}`;
  return { formattedOffset, timeZoneAbbr, currentTime, utcOffset, utcOffsetHours, utcOffsetSeconds, utcOffsetMinutes }
};

const formatToLocalTime = (isoTimestamp, format = "DD/MM HH:mm:ss") => {
  const { utcOffset } = getCurrentTimeAndOffset();

  // Parse the ISO timestamp using moment and create a Moment.js object
  const date = momentTz.utc(isoTimestamp);

  // Check if the parsed date is valid
  if (date.isValid()) {
    // Adjust the time based on the current UTC offset
    const adjustedTime = date.valueOf() + utcOffset * 60 * 1000;

    // Create a new Moment.js object with the adjusted time
    const adjustedDate = momentTz(adjustedTime);

    // Format the adjusted date and time to the provided format in local time
    return adjustedDate.format(format);
  } else {
    // Handle invalid input date
    return "Invalid timestamp";
  }
};

const decodeBase64Url = (base64Url) => {
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return atob(base64);
}
const decodeToken = token => {
  const [_headerB64, payloadB64, _signature] = token.split('.');
  const decodedPayload = JSON.parse(decodeBase64Url(payloadB64));
  return decodedPayload;
}
function snakeCaseToCapitalizedWords(snakeCaseString = "") {
  if (!snakeCaseString) {
    return "";
  }
  // Split the snake case string by underscore
  const words = snakeCaseString?.split("_");
  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  // Join the words with a space and return the result
  return capitalizedWords.join(" ");
}
const capitalizeFirstLetter = (str) => {
  if (typeof str !== 'string') return ""
  // Check if the input is not an empty string
  if (str.length === 0) {
    return str;
  }
  // Capitalize the first letter and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function convertCamelCaseToCapitalizedWords(input) {
  // Add a space before each capital letter (except the first one)
  let result = input.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
}
function filterEmptyValues(value) {
  if (isObject(value)) {
    const nestedFiltered = filterEmptyObjects(value);
    return !isEmpty(nestedFiltered);
  } else if (isString(value)) {
    return !isEmpty(value.trim());
  } else if (isArray(value)) {
    const arrayFiltered = value.filter((item) => !isNil(item) && filterEmptyValues(item));
    return !isEmpty(arrayFiltered);
  }
  return !isNil(value);
}

function filterEmptyObjects(obj) {
  // Recursively filter out empty values
  const filteredObj = pickBy(obj, filterEmptyValues);

  return filteredObj;
}

function remainingDays(targetDate) {
  // Parse the target date using Moment.js
  const targetDateMoment = moment(targetDate, 'YYYY-MM-DD');

  // Get the current date using Moment.js
  const currentDateMoment = moment();

  // Calculate the difference in days
  const daysRemaining = targetDateMoment.diff(currentDateMoment, 'days');

  return daysRemaining;
}

function remainingDaysPlusOne(targetDate) {
  // Parse the target date using Moment.js
  const targetDateMoment = moment(targetDate, 'YYYY-MM-DD');

  // Get the current date using Moment.js
  const currentDateMoment = moment();

  // Calculate the difference in days
  // days diff is 0 if the target date is today, even if the time is different
  //days diff is 1 if the target date is tomorrow
  //days should be integer and not decimals
  const daysRemaining = Math.ceil(targetDateMoment.diff(currentDateMoment, 'days', true));

  return daysRemaining;
}

function convertStringsToNumbers(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      // Recursively convert strings to numbers in nested objects
      obj[key] = convertStringsToNumbers(obj[key]);
    } else if (typeof obj[key] === 'boolean' || obj[key] === 'true') {
      // Convert string to number if it's a number
      continue;
    }
    else if (!isNaN(obj[key])) {
      // Convert string to number if it's a number
      obj[key] = parseFloat(obj[key]);
    }
  }
  return obj;
}
function getDataURIWithoutPrefix(dataURI) {
  const uri = dataURI.split(",");
  if (uri.length > 1){
    return uri[1]
  }else {
    return dataURI;
  }
}

const sideDropDownValue = ["Ask", "Bid", "Mid"]
const contractTypeDDValue = ["Call", "Put"]
const typeDDValue = [
  "AUTO",
  "SLAN",
  "ISOI",
  "MLET",
  "MLAT",
  "CANC",
  "TLET",
  "MESL",
  "SLAI",
  "CNOL",
  "SLFT",
  "MLFT"
]
const exchangeDDValue = [
  "GMNI",
  "ISE",
  "NSDQ",
  "MIAX",
  "MPRL",
  "PHLX",
  "NYSE",
  "MEMX",
  "EMLD",
  "CBOE",
  "BOX",
  "AMEX",
  "NQBX",
  "MCRY",
  "BATS",
  "EDGO",
  "C2"
]
const flagDDValue = [
  "cross",
  "sweep",
  'floor'
]
const sentimentDDValue = [
  "bullish",
  "bearish",
  // 'neutral'
]

function isValidUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}


export {
  errorsGetFirst,
  apiUrl,
  getUrlParameter,
  setUserSession,
  getUserSession,
  destroyUserSession,
  setCookie,
  decodeToken,
  getCookie,
  baseApiUrl,
  getRedirectPath,
  flattenErrors,
  QueryStringToJSON,
  withRouter,
  errorMessage,
  convertStringsToNumbers,
  SuccessMessage,
  jsonToQueryParms,
  currenTimeZone,
  sideDropDownValue,
  contractTypeDDValue,
  typeDDValue,
  exchangeDDValue,
  flagDDValue,
  sentimentDDValue,
  capitalizeFirstLetter,
  snakeCaseToCapitalizedWords,
  filterEmptyObjects,
  filterEmptyValues,
  getCurrentTimeAndOffset,
  remainingDays,
  remainingDaysPlusOne,
  formatToLocalTime,
  convertCamelCaseToCapitalizedWords,
  getDataURIWithoutPrefix,
  base64ToBlob,
  isValidUrl

}