import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { userDetailsApi } from "src/services/authService";
import { setSession } from "src/utils/axios";
import { localStorageKey } from "src/utils/config";
import { isEmpty } from "lodash";
import { isExpired } from "src/utils/time";
import { useStableNavigate } from "./StableNavigateContext";
import { SUBSCRIPTION_PLANS } from "src/constant";
import hasAccess from "src/utils/guard";
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const PremiumUserContext = createContext(false);

export const useIsPremiumUser = () => {
  return useContext(PremiumUserContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isPremium, setIsPremium] = useState(false);

  const initialized = useRef(false);
  // const navigate = useNavigate();
  const navigate = useStableNavigate()
  const initialize = async () => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    const token = getToken();
    if (!token) {
      return;
    }
    await setSession(token);
    const userData = await getUserData();
    if (!isEmpty(userData)) {
      console.log("got user data", userData);
      setUser(userData);
      setIsPremium(hasAccess([SUBSCRIPTION_PLANS.PREMIUM], userData));

      if (!userData.isB1Form || userData.isB1Form === false) {
        navigate("/b1form", { replace: true });
      } else if (
        isExpired(userData?.current_period_end)
        ||
        (userData?.subscriptionStatus !== "active")
      ) {
        navigate("/pricing", { replace: true });
      }
    }
  };

  useEffect(() => {
    initialize();
  }, [user]);

  const logout = () => {
    localStorage.removeItem(localStorageKey.userInfo);
    localStorage.removeItem(localStorageKey.token);
    setUser(null);
    setIsPremium(false);
    window.location.replace("/");
  };
  // const setUserData = (data) => {
  //   setUser(data);
  // };

  const getUserData = async () => {
    const response = await userDetailsApi();
    return response;
  };
  const getToken = () => {
    return localStorage.getItem(localStorageKey.token);
  };
  const refreshUserDetails = async () => {
    const user = await getUserData();
    setUser(user);
    setIsPremium(hasAccess([SUBSCRIPTION_PLANS.PREMIUM], user));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        getUserData,
        setUserData: setUser,
        logout,
        getToken,
        refreshUserDetails,
      }}
    >
      <PremiumUserContext.Provider value={isPremium}>
        {children}
      </PremiumUserContext.Provider>
    </AuthContext.Provider>
  );
};
