import React from "react";
import CopyRights from "./CopyRights";
import TechnologyPartner from "./TechnologyPartner";

function FooterLegal() {
  return (
    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center text-gray-600 fw-light text-center fs-8 mb-0">
      <CopyRights />
      <div className="d-flex flex-row align-items-center justify-content-center text-gray-600 fw-light text-center fs-8 mb-0">
        <span>|</span>
        <a href="/faq" className="text-gray-600 px-3 fw-light text-hover-dark">
          FAQ
        </a>
        <span className="d-none d-sm-inline-block">|</span>
        <a href="/contact-us" className="text-gray-600 px-3 fw-light text-hover-dark">
          Contact Us
        </a>
        <span>|</span>
        <a href="/terms-condition" className="text-gray-600 px-3 fw-light text-hover-dark">
          Terms & Conditions
        </a>
        <span>|</span>
        <a href="/privacy-policy" className="text-gray-600 px-3 fw-light text-hover-dark">
          Privacy Policy
        </a>
        <span>|</span>
        <span className="px-3">Not Financial Advice</span>
        <span className="d-none d-sm-inline-block">|</span>
      </div>
      <TechnologyPartner />
    </div>
  );
}

export default FooterLegal;
