import React, { useState, useContext, useEffect, useMemo } from "react";
import { withRouter } from "../../utils";
// import Userfront from "@userfront/react";
import { SocketioContext } from "../../context/SocketioContext";
import { ThemeDetailsContext } from "../../context/ThemeContext";
import MarketSentiments from "./MarketSentiments";
import MobileMenu from "./Mobile/MobileMenu";
import MobileMarketSentiment from "./Mobile/MobileMarketSentiment";

import { NavLink, useNavigate } from "react-router-dom";
import {
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useAuth } from "src/context/AuthContext";
import { Logo } from "src/components/Logo";
import ToggleSwitch from "../../components/ToggleSwitch";
import hasAccess from "src/utils/guard";
import { SUBSCRIPTION_PLANS } from "src/constant";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const PrivateHeader = () => {
  const { liveFeed, setLiveFeed } = useContext(SocketioContext);
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const isPremiumUser = hasAccess([SUBSCRIPTION_PLANS.PREMIUM], user)
  return (
    <>
      <header className={`header-dashboard ${isPremiumUser? "premium-header-dashboard" : "px-3 py-3"}  d-none d-lg-block `}>
       {isPremiumUser &&  <div className="premium-banner d-none d-lg-block" >You are subscribed to a 15-minute delayed feed for all data, prices and aggregate. <NavLink to={'https://billing.stripe.com/p/login/8wM6pg19F989cYEeUU'} target="_blank" className="fw-semibold"> <u>Upgrade</u></NavLink>  your account to access real-time data, prices and aggregate.</div>}
        <div className={`d-flex align-items-center ${isPremiumUser ? ' mx-3 my-3 ': ''} `}>
          <div
            className="text-start d-inline-flex cursor-pointer pe-8 justify-content-center"
            onClick={() => navigate("/")}
          >
            <Logo className="logo-vertical" />
          </div>
          <div className="flex-grow-1">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-8 col-sm-8">
                  <MarketSentiments />
                </div>
                <div className="col-4 col-sm-4">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-flush btn-live-flow d-inline-flex mx-2 mx-lg-5 mx-xl-6 align-items-center gap-1"
                        style={{ fontSize: 13 }}
                      >
                        <span
                        className="fs-7 fs-xl-6 fw-medium"

                          style={{
                            color: !liveFeed ? "rgb(219, 0, 0)" : "gray",
                            marginRight: 2
                          }}
                        >
                          Paused
                        </span>
                        <ToggleSwitch
                          checked={liveFeed}
                          onChange={() => setLiveFeed(!liveFeed)}
                        />
                        <span
                        className="fs-7 fs-xl-6 fw-medium"
                        style={{
                          // fontWeight: liveFeed ? 600 : 500,
                          color: liveFeed ? "rgb(6, 137, 61)" : "gray",
                          marginLeft: 2
                        }}
                        >Live Flow</span>
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-start border-gray-300">
                      <Dropdown className="dropdown-profile ms-3 ms-lg-5 ms-xl-10">
                        <Dropdown.Toggle
                          variant="default"
                          className="py-1 btn-flush"
                        >
                          {user?.name}{" "}
                          <FontAwesomeIcon
                            icon={icon({ name: "chevron-down" })}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => navigate("/my-account")}
                          >
                            My Account
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => logout()}>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    {/* <div
                  className={`btn-live-flow d-flex align-items-center px-15 px-xl-20 ${
                    plan.planId !== process.env.REACT_APP_LIVE_FEED_PLAN_ID
                      ? "disabled"
                      : ""
                  }`}
                >
                  <span className="btn btn-default pe-none btn-flush me-5">
                    Live Flow
                  </span>
                  <ConditionalWrapper
                    condition={
                      plan.planId !== process.env.REACT_APP_LIVE_FEED_PLAN_ID
                    }
                    wrapper={(children) => (
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            id="button-tooltip"
                            onClick={() => {
                              navigate("/my-account/update/plan");
                            }}
                          >
                            Upgrade to preminum plus to see live flow
                          </Tooltip>
                        }
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                      >
                        {children}
                      </OverlayTrigger>
                    )}
                  >
                  </ConditionalWrapper>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm rounded-circle btn-play-flow"
                    onClick={() => {
                      setLiveFeed(!liveFeed);
                      if (plan.planId === process.env.REACT_APP_LIVE_FEED_PLAN_ID) {
                         return setLiveFeed(!liveFeed)
                       }
                     return () => { }
                    }}
                  >
                    {!liveFeed ? (
                      <FontAwesomeIcon icon={icon({ name: "play" })} />
                    ) : (
                      <FontAwesomeIcon icon={icon({ name: "pause" })} />
                    )}
                    
                  </button>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu />
    </>
  );
};
export default PrivateHeader;
