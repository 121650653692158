import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const Treemap = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0) return;  // Ensure data is not undefined or empty

    const width = 1200;
    const height = 800;

    // Select the SVG element and clear any previous content
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    // Append a background rectangle to the SVG
    svg.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'black');  // Set the background color to black

    // Group data by sector and industry
    const groupedData = d3.groups(data, d => d.sector, d => d.industry);

    // Create a new hierarchy with sectors as parent nodes, industries as intermediate nodes, and symbols as leaf nodes
    const root = d3.hierarchy({
      children: groupedData.map(([sector, industries]) => ({
        sector,
        children: industries.map(([industry, symbols]) => ({
          industry,
          children: symbols
        }))
      }))
    })
      .sum(d => d.marketCap || 1) // Use marketCap to determine the size of the blocks (fallback to 1)
      .sort((a, b) => b.value - a.value); // Sort by marketCap

    // Create treemap layout
    const treemapLayout = d3.treemap()
      .size([width, height])
      .paddingInner(2)  // Padding between blocks
      .paddingTop(30)   // Add space at the top for sector and industry labels
      .round(true);

    // Apply treemap layout to the root hierarchy
    treemapLayout(root);

    // Custom color mapping based on the percentage change
    const getColorByPercentage = (changePercentage) => {
    if (changePercentage <= -3) return '#F53538';   // Between -∞ and -3%
    if (changePercentage > -3 && changePercentage <= -2) return '#BF4045';  // Between -3% and -2%
    if (changePercentage > -2 && changePercentage <= -1) return '#8B444E';  // Between -2% and -1%
    
    // Grey color for any percentage between -1% and +1%
    if (changePercentage > -1 && changePercentage < 1) return '#414554';   // Between -1% and 1%
    
    if (changePercentage === 0) return '#414554';   // Exactly 0%, but handled as grey too
    if (changePercentage >= 1 && changePercentage <= 2) return '#2F9E4E';   // Between 1% and 2%
    if (changePercentage > 2 && changePercentage <= 3) return '#32CC5B';   // Between 2% and 3%
    if (changePercentage > 3) return '#32CC5B';    // For 3% or more
    return '#ccc';  // Default color if percentage change is outside the range
    };


    // Append a group for each node in the treemap
    const nodes = svg
      .selectAll('g')
      .data(root.leaves())  // Use the leaf nodes (symbols)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${d.x0},${d.y0})`);

    // Create rectangles for each node (with borders)
    nodes
      .append('rect')
      .attr('width', d => d.x1 - d.x0)
      .attr('height', d => d.y1 - d.y0)
      .attr('fill', d => {
        if (d.data.price !== undefined && d.data.previousClose !== undefined) {
          // Calculate the percentage change
          const changePercentage = ((d.data.price - d.data.previousClose) / d.data.previousClose) * 100;
          return getColorByPercentage(changePercentage);  // Use the custom color mapping
        }
        return '#ccc';  // Default color if price or previousClose is missing
      })
      .attr('stroke', 'white')  // Change the stroke to white for better contrast on black background
      .attr('stroke-width', 2);

    // Function to calculate the font size that fits inside the container
    const getFontSize = (width, height, textLength) => {
      const scaleFactor = 0.6; // Adjust this factor to control how much of the box is filled by the text
      const minFontSize = 10;
      const fontSize = Math.min((width / textLength) * scaleFactor, height * scaleFactor);
      return Math.max(fontSize, minFontSize); // Ensure minimum font size for very small boxes
    };

    // Add text labels for symbol inside each block and auto-adjust font size
    /*nodes
      .append('text')
      .attr('x', d => (d.x1 - d.x0) / 2)
      .attr('y', d => (d.y1 - d.y0) / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .text(d => d.data.symbol || '')
      .attr('fill', 'white')
      .style('font-size', function(d) {
        const width = d.x1 - d.x0;
        const height = d.y1 - d.y0;
        const symbol = d.data.symbol || '';
        const textLength = symbol.length;
        return `${getFontSize(width, height, textLength)}px`;  // Dynamically calculate font size
      })
      .style('pointer-events', 'none'); */
      // Add text labels for symbol inside each block and auto-adjust font size
nodes
.append('text')
.attr('x', d => (d.x1 - d.x0) / 2)
.attr('y', d => (d.y1 - d.y0) / 2)
.attr('dy', '.35em')
.attr('text-anchor', 'middle')
.text(d => {
  // Hide text if the tile size is smaller than 10x10 pixels
  const width = d.x1 - d.x0;
  const height = d.y1 - d.y0;
  if (width < 10 || height < 10) {
    return '';  // Don't display text if tile is too small
  }
  return d.data.symbol || '';  // Display the symbol if tile is large enough
})
.attr('fill', 'white')
.style('font-size', function(d) {
  const width = d.x1 - d.x0;
  const height = d.y1 - d.y0;
  const symbol = d.data.symbol || '';
  const textLength = symbol.length;
  // Dynamically calculate font size based on tile size
  return `${Math.max(Math.min((width / textLength) * 0.6, height * 0.6), 10)}px`;
})
.style('pointer-events', 'none');

// Add percentage change text below the symbol
nodes
.append('text')
.attr('x', d => (d.x1 - d.x0) / 2)
.attr('y', d => (d.y1 - d.y0) / 2 + 15)
.attr('dy', '.35em')
.attr('text-anchor', 'middle')
.text(d => {
  // Hide percentage text if the tile size is smaller than 10x10 pixels
  const width = d.x1 - d.x0;
  const height = d.y1 - d.y0;
  if (width < 10 || height < 10) {
    return '';  // Don't display percentage if tile is too small
  }
  if (d.data.price !== undefined && d.data.previousClose !== undefined) {
    const changePercentage = ((d.data.price - d.data.previousClose) / d.data.previousClose) * 100;
    return `${changePercentage.toFixed(2)}%`;
  }
  return '';
})
.attr('fill', 'white')
.style('font-size', '12px')
.style('pointer-events', 'none');


    // Add percentage change text below the symbol
    nodes
      .append('text')
      .attr('x', d => (d.x1 - d.x0) / 2)
      .attr('y', d => (d.y1 - d.y0) / 2 + 15)
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .text(d => {
        if (d.data.price !== undefined && d.data.previousClose !== undefined) {
          const changePercentage = ((d.data.price - d.data.previousClose) / d.data.previousClose) * 100;
          //return `${changePercentage.toFixed(2)}%`;
          // Add "+" sign if the percentage gain is greater than 0
            return changePercentage > 0 
                ? `${changePercentage.toFixed(2)}%` 
                : `${changePercentage.toFixed(2)}%`;
        }
        return '';
      })
      .attr('fill', 'white')
      .style('font-size', '12px');

    // Add sector labels
    svg
      .selectAll('g.sector')
      .data(root.children)  // Sector-level nodes
      .enter()
      .append('text')
      .attr('x', d => d.x0 + 5)
      .attr('y', d => d.y0 - 20)  // Adjust the position of sector labels to avoid overlap with industry labels
      .text(d => d.data.sector)
      .attr('fill', 'white')  // Set sector label color to white for better visibility on black background
      .style('font-size', '16px')
      .style('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .style('pointer-events', 'none');

    // Add industry labels within each sector
    svg
      .selectAll('g.industry')
      .data(root.descendants().filter(d => d.depth === 2))  // Industry-level nodes
      .enter()
      .append('text')
      .attr('x', d => d.x0 + 5)
      .attr('y', d => d.y0 + 15)  // Position the industry label within the block
      .text(d => d.data.industry)
      .attr('fill', 'white')  // Set industry label color to white for contrast
      .style('font-size', '12px')
      .attr('text-anchor', 'start')
      .style('pointer-events', 'none');
  }, [data]);

  return (
    <svg ref={svgRef} width={1200} height={800}></svg>  // Larger width and height for heatmap
  );
};

export default Treemap;
