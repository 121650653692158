import PublicHeader from "./PublicHeader";
import PrivateHeader from "./PrivateHeader";
import SideBar from "./SideBar";
import TopBatch from "./TopBatch";

const Header = {
  Public: PublicHeader,
  Private: PrivateHeader,
  SideBar: SideBar,
  TopBatch: TopBatch,
};

export default Header;
