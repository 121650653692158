import iconAnounce from "../../assets/images/icon-1(9).png";
import close from "../../assets/images/close.png";
import { useEffect, useState } from "react";
import { setUserSession, getUserSession } from '../../utils'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';


const   TopBatch = () => {
  const [lgShow, setLgShow] = useState(false);
  const userPlan = getUserSession("planDetail")
  const navigate = useNavigate()
  useEffect(() => {
    // if (userPlan.planId === process.env.REACT_APP_FREE_PLAN_ID || userPlan.planId === process.env.REACT_APP_15MINS_FEED_PLAN_ID) {
    //   setLgShow(true)
    // }
  }, [])
  if (!lgShow){
       return <></>
  }
  return (
    <div className="topbar-alert bg-primary py-2">
    <div className="container-fluid px-xl-20">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <button type="button" className="btn btn-sm btn-icon btn-flush me-6 text-white" onClick={() => setLgShow(false)}>
            <FontAwesomeIcon icon={icon({name: 'xmark'})} />
          </button>
        </div>
        <div className="d-flex align-items-center px-3">
          <img src={iconAnounce} alt="" className="me-5"></img>
          <p className="mb-0 fs-6 text-white">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
        <div>
          <button type="button" className="btn bg-white text-primary text-active-white bg-active-primary btn-sm py-2 px-4" onClick={() => navigate("/my-account/update/plan")}>UPGRADE</button>
        </div>
      </div>
    </div>
  </div>  );
}
export default TopBatch;