import { useNavigate } from "react-router-dom";
import SVG from 'react-inlinesvg'
import LogoIconLight from "../assets/images/icon-unusual-flow-light.svg";
import LogoIconPrimary from "../assets/images/icon-unusual-flow-primary.svg";
import LogoIconDark from "../assets/images/icon-unusual-flow-dark.svg";

//const toAbsoluteUrl = (pathname="") => process.env.PUBLIC_URL + pathname
// const { env } = process;
// env.PUBLIC_URL = env.PUBLIC_URL ?? '';

const Logo = ({icononly}) => {
//const navigate = useNavigate();
  return (

    <>
      <span className="logo">
        <img src={LogoIconPrimary} alt="Unusual Flow" className="logo-icon"></img>
        {!icononly ? <span className="logo-text text-dark">Unusual Flow</span> : '' }
      </span>
    </>
  )
}

export {Logo}