import React from "react";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToggleSwitch = ({ checked, onChange, className="" }) => {
  return (
    <div className={`toggle-switch-container ${className}`} >
      <label className={`toggle-switch ${checked ? "checked" : ""}`}>
        <input type="checkbox" checked={checked}
         onChange={onChange}
          />
        <span className="toggle-slider">
          <FontAwesomeIcon
            icon={checked ? icon({ name: "play", color: 'green' }) : icon({name: 'pause'})}
            fontSize={12}
            color="red"
          />
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
