import axios from 'axios'
//import Raven from 'raven-js'
import { baseApiUrl, getCookie } from '../utils'
import { localStorageKey } from './config';
// import Userfront from "@userfront/react";
const getClient = (baseUrl = null) => {

  const options = {
    baseURL: baseUrl
  }

  const token = localStorage.getItem(localStorageKey.token);
  if (token) {
    options.headers = {
      Authorization: `${token}`,
    }
  }

  const client = axios.create(options)

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    (requestError) => {
      //Report to cloud error service
      //Raven.captureException(requestError)

      return Promise.reject(requestError)
    },
  )

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    (error) => {
      if (error.response.status >= 500) {
        //Report to cloud error service
        //Raven.captureException(error)
      }

      return Promise.reject(error)
    },
  )

  return client
}


export default {
  get(url, conf = {}) {
    return getClient(baseApiUrl).get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  delete(url, conf = {}) {
    return getClient(baseApiUrl).delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  head(url, conf = {}) {
    return getClient(baseApiUrl).head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  options(url, conf = {}) {
    return getClient(baseApiUrl).options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  post(url, data = {}, conf = {}) {
    return getClient(baseApiUrl).post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  put(url, data = {}, conf = {}) {
    return getClient(baseApiUrl).put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  patch(url, data = {}, conf = {}) {
    return getClient(baseApiUrl).patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
}