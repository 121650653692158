import momentTz from "moment-timezone";
function isMarketOpen() {
  const start = "09:29:50";
  const end = "16:30:00";
  const fmt = "HH:mm:ss";
  const timezone = "America/New_York"; // 'America/New_York' is the timezone for Eastern Standard Time

  const now = momentTz().tz(timezone); 
  const startTz = momentTz.tz(start, fmt, timezone);
  const endTz = momentTz.tz(end, fmt, timezone);
  const isMarketHours = now.isBetween(startTz, endTz);

  // Check if it's a weekday (Monday to Friday) and within market hours (9:30am - 4:30pm EST)
  const isWeekday = now.isoWeekday() >= 1 && now.isoWeekday() <= 5; // 1 is Monday, 5 is Friday
//   return true;
  return isWeekday && isMarketHours;
}
function isExpired(timestamp) {
  if (timestamp === false){
    return true;
  }
  // Convert the timestamp to a moment object
  const momentObj = momentTz(timestamp);
  // Get the current moment in UTC timezone
  const currentMoment = momentTz.utc();

  // Check if the timestamp is before the current moment
  const expired = momentObj.isBefore(currentMoment)
  return expired;
}



export { isMarketOpen, isExpired };
