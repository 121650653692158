import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/images/something_went_wrong.svg";
function Fallback({ error, resetErrorBoundary }) {
  const navigate = useNavigate();
  const [reset, setReset] = useState(2);

  const navigateHandler = () => {
    navigate("/dashboard");
    setTimeout(() => {
      resetErrorBoundary();
    }, 100);
  };
  useEffect(() => {
    let isMounted = true;

    const id = setInterval(() => {
      if (isMounted && reset > 0) {
        setReset((prev) => prev - 1);
      } else if (isMounted) {
        clearInterval(id);
        // Navigate to the dashboard after 3 seconds
        navigateHandler();
      }
    }, 1000);

    return () => {
      isMounted = false;
      clearInterval(id);
    };
  }, [reset, navigate]);

  console.log({ error });
  return (
    <>
      <div
        role="alert"
        style={{ display: "grid", placeItems: "center", height: "100vh" }}
      >
        <img alt="error" src={errorImg} />
        <h1 style={{ color: "white" }}>Something went wrong</h1>
        <pre style={{ color: "red" }}>
          Going back to Home in {reset + 1} seconds
          {reset === 0 && (
          <p style={{ color: "white" }}>
            If not automatically navigated, <a href="/dashboard">click me</a>
          </p>
        )}
        </pre>
        
      </div>
    </>
  );
}

export default Fallback;
