import React from "react";
import FooterLegal from "./FooterLegal";
import TechnologyPartner from "./TechnologyPartner";
import CopyRights from "./CopyRights";

function FooterDashboard() {
  return (
    <footer className="footer-dashboard pb-4">
      <div className="container-fluid">
        <FooterLegal/>        
      </div>
    </footer>
  );
}

export default FooterDashboard;
