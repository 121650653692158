import React, { Component, useState, useRef, createRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { withRouter } from "../../utils";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

// Menu Icons Dark Mode
import iconDashboardLight from "../../assets/images/menu-icons/icon-dashboard-light.svg";
import iconHotFireLight from "../../assets/images/menu-icons/icon-hot-fire-light.svg";
import iconNewsLight from "../../assets/images/menu-icons/icon-news-light.svg";
import iconLiveFlowLight from "../../assets/images/menu-icons/icon-bar-graph-light.svg";
import iconTickerLight from "../../assets/images/menu-icons/icon-ticket-light.svg";
import iconFilterLight from "../../assets/images/menu-icons/icon-filter-sliders-light.svg";
import iconAlertLight from "../../assets/images/menu-icons/icon-bell-light.svg";
import iconSettingLight from "../../assets/images/menu-icons/icon-cog-light.svg";
import iconLogoutLight from "../../assets/images/menu-icons/icon-logout-light.svg";
import iconDarkPoolLight from "../../assets/images/menu-icons/icon-trend-tag-light.svg";

// Menu Icons Light Mode
import iconDashboardPrimary from "../../assets/images/menu-icons/icon-dashboard-primary.svg";
import iconHotFirePrimary from "../../assets/images/menu-icons/icon-hot-fire-primary.svg";
import iconNewsPrimary from "../../assets/images/menu-icons/icon-news-primary.svg";
import iconLiveFlowPrimary from "../../assets/images/menu-icons/icon-bar-graph-primary.svg";
import iconTickerPrimary from "../../assets/images/menu-icons/icon-ticket-primary.svg";
import iconFilterPrimary from "../../assets/images/menu-icons/icon-filter-sliders-primary.svg";
import iconAlertPrimary from "../../assets/images/menu-icons/icon-bell-primary.svg";
import iconSettingPrimary from "../../assets/images/menu-icons/icon-cog-primary.svg";
import iconLogoutPrimary from "../../assets/images/menu-icons/icon-logout-primary.svg";
import iconDarkPoolPrimary from "../../assets/images/menu-icons/icon-trend-tag-primary.svg";

import { ThemeDetailsContext } from "../../context/ThemeContext";
import { useAuth } from "src/context/AuthContext";


class SideBar extends Component {
  static contextType = ThemeDetailsContext;
  constructor(props) {
    super(props);
    this.state = {
      currentMenu: "",
    };
  }
  componentDidMount() {
    this.updateCurrentMenu();
  }
  componentDidUpdate(prevProps) {
    if (prevProps?.router?.location?.pathname !==  this.props?.router?.location?.pathname) {
      this.updateCurrentMenu();
    }
  }

  updateCurrentMenu = () => {
    this.setState({ currentMenu:  this.props?.router?.location?.pathname });
  };

  logOut = () => {
    localStorage.removeItem("planDetail");
    const { logout } = useAuth();
    logout();
    const navigate = useNavigate();
    navigate("/login");
  };
  navigateTo = (menu) => {
    this.props.router.navigate(`/${menu}`, { state: { menu: menu } });
  };

  render() {
    const { currentMenu } = this.state;
    const { setTheme, theme } = this.props;
    const expandedMenu = this.context?.expandedMenu;
    const setExpandedMenu = this.context?.setExpandedMenu;
    return (
      <>
        <aside
          className={`app-aside d-flex flex-column justify-content-between ${
            expandedMenu ? "menu-expanded" : ""
          }`}
        >
          <nav
            className={`side-nav side-nav-primary ${
              expandedMenu ? "menu-expanded" : ""
            }`}
          >
            <div className="nav d-flex flex-column align-items-center">
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/dashboard" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("dashboard");
                }}
              >
                <img
                  src={
                    theme == "light" ? iconDashboardPrimary : iconDashboardLight
                  }
                  alt=""
                  data-tooltip-id="menu-dashboard"
                  data-tooltip-content="Dashboard"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-dashboard" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Dashboard" : ""}
                </small>
              </a>
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/heatmap" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("heatmap");
                }}
              >
                <img
                  src={theme == "light" ? iconDarkPoolPrimary : iconDarkPoolLight}
                  alt=""
                  data-tooltip-id="menu-heatmap"
                  data-tooltip-content="Heatmap"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-gainerlooser" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Heatmap" : ""}
                </small>
              </a>
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/top-gainer-loser" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("top-gainer-loser");
                }}
              >
                <img
                  src={theme == "light" ? iconFilterPrimary : iconFilterLight}
                  alt=""
                  data-tooltip-id="menu-gainerlooser"
                  data-tooltip-content="Top Gainer/Losers"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-gainerlooser" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Top Gainer/Losers" : ""}
                </small>
              </a>
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/hottest-contracts" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("hottest-contracts");
                }}
              >
                {currentMenu === "/hottest-contracts" ? (
                  <img
                    src={iconHotFirePrimary}
                    alt=""
                    data-tooltip-id="menu-tickers"
                    data-tooltip-content="Hottest Contracts/Chains"
                    data-tooltip-place="right"
                  ></img>
                ) : (
                  <img
                    src={
                      theme == "light" ? iconHotFirePrimary : iconHotFireLight
                    }
                    alt=""
                    data-tooltip-id="menu-tickers"
                    data-tooltip-content="Hottest Contracts/Chains"
                    data-tooltip-place="right"
                  ></img>
                )}
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-tickers" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Hottest Contracts/Chains" : ""}
                </small>
              </a>
              {/* <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/dark-pool" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("dark-pool");
                }}
              >
                <img
                  src={
                    theme == "light" ? iconDarkPoolPrimary : iconDarkPoolLight
                  }
                  alt=""
                  data-tooltip-id="menu-dp"
                  data-tooltip-content="Dark Pool"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-dp" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Dark Pool" : ""}
                </small>
              </a> */}
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/unusual-flow-picks" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("unusual-flow-picks");
                }}
              >
                <img
                  src={
                    theme == "light" ? iconLiveFlowPrimary : iconLiveFlowLight
                  }
                  alt=""
                  data-tooltip-id="menu-uf"
                  data-tooltip-content="Unusual Flow Picks"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-uf" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Unusual Flow Picks" : ""}
                </small>
              </a>
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/tickers" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("tickers");
                }}
              >
                <img
                  src={theme == "light" ? iconTickerPrimary : iconTickerLight}
                  alt=""
                  data-tooltip-id="menu-tickers"
                  data-tooltip-content="Tickers"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-tickers" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Tickers" : ""}
                </small>
              </a>
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/bookmark" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("bookmark");
                }}
              >
                <span
                  data-tooltip-id="menu-bookmark"
                  data-tooltip-content="Bookmarks"
                  data-tooltip-place="right"
                  className={`${
                    theme == "dark" ? "btn-primary" : "text-primary"
                  }`}
                >
                  {expandedMenu ? (
                    <FontAwesomeIcon
                      fontSize={16}
                      icon={icon({ name: "star" })}
                    />
                  ) : (
                    <>
                      <FontAwesomeIcon
                        fontSize={16}
                        icon={icon({ name: "star" })}
                      />
                      <Tooltip className="nav-tooltip" id="menu-bookmark" />
                    </>
                  )}

                  <small className="link-text d-block">
                    {expandedMenu ? "Bookmarks" : ""}
                  </small>
                </span>
              </a>
              <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/news" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("news");
                }}
              >
                <img
                  src={theme == "light" ? iconNewsPrimary : iconNewsLight}
                  alt=""
                  data-tooltip-id="menu-news"
                  data-tooltip-content="News"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-news" />
                )}
                <small className="link-text">
                  {expandedMenu ? "News" : ""}
                </small>
              </a>
              {/* <a
                className={`nav-link d-flex flex-column align-items-center ${
                  currentMenu === "/alerts" ? "active" : ""
                }`}
                onClick={() => {
                  this.navigateTo("alerts");
                }}
              >
                <img
                  src={theme == "light" ? iconAlertPrimary : iconAlertLight}
                  alt=""
                  data-tooltip-id="menu-alerts"
                  data-tooltip-content="Alerts"
                  data-tooltip-place="right"
                ></img>
                {expandedMenu ? (
                  ""
                ) : (
                  <Tooltip className="nav-tooltip" id="menu-alerts" />
                )}
                <small className="link-text">
                  {expandedMenu ? "Alerts" : ""}
                </small>
              </a> */}
            </div>
          </nav>
          <button
            type="button"
            onClick={() => {
              setExpandedMenu(!expandedMenu);
            }}
            className={`btn btn-icon h-20px w-20px btn-sm rounded-circle aside-toggle ${
              theme == "light" ? "btn-primary" : "bg-white text-primary"
            }`}
          >
            <span
              data-tooltip-id="menu-togglemenu"
              data-tooltip-content={expandedMenu ? "Collapse" : "Expand"}
              data-tooltip-place="right"
            >
              {expandedMenu ? (
                <FontAwesomeIcon icon={icon({ name: "chevron-left" })} />
              ) : (
                <FontAwesomeIcon icon={icon({ name: "chevron-right" })} />
              )}
            </span>
            <Tooltip className="nav-tooltip fs-7" id="menu-togglemenu" />
          </button>
          <nav className="side-nav side-nav-secondary mt-auto">
            <div className="nav d-flex flex-column align-items-center">
              {/* <a className={`nav-link d-flex flex-column align-items-center ${currentMenu === "my-account" ? "active" : ""}`} onClick={() => { this.navigateTo('my-account') }}>
                  <img src={theme == "light" ? iconSettingPrimary : iconSettingLight} alt=""
                    data-tooltip-id="menu-settings"
                    data-tooltip-content="Settings"
                    data-tooltip-place="right"></img>
                    {expandedMenu ? "" : <Tooltip className="nav-tooltip" id="menu-settings" />}
                  <small className='link-text'>{expandedMenu ? "Settings" : ""}</small>
                </a>
                <a className="nav-link d-flex flex-column align-items-center" onClick={() => { this.logOut() }}>
                  <img src={theme == "light" ? iconLogoutPrimary : iconLogoutLight} alt=""
                    data-tooltip-id="menu-logout"
                    data-tooltip-content="Logout"
                    data-tooltip-place="right"></img>
                    {expandedMenu ? "" : <Tooltip className="nav-tooltip" id="menu-logout" />}
                  <small className='link-text'>{expandedMenu ? "Logout" : ""}</small>
                </a> */}
              <div className="theme-toggle">
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="themeToggle"
                    role="switch"
                    checked={theme == "dark" ? true : false}
                    onClick={() => {
                      setTheme(theme == "dark" ? "light" : "dark");
                    }}
                  />
                  <label htmlFor="themeToggle" className="form-check-label">
                    {theme == "dark"
                      ? "Switch to light mode"
                      : "Switch to dark mode"}
                  </label>
                  <Tooltip className="nav-tooltip" id="menu-toggletheme" />
                </div>
              </div>
            </div>
          </nav>

          {/* <div className={`menu-scroll ${expandedMenu ? "menu-expanded" : ""}`} id="side-barmenu">
            <div className="menu-side-icon">
              
              
              <div className="bttm-menu-icon">
                
              </div>
            </div>
          </div> */}
        </aside>
      </>
    );
  }
}

export default withRouter(SideBar);
